<template>

  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Insumos</div>
    <div class="text-500 mb-5">Insira os insumos de sua preferência.</div>
    <div class="surface-card p-4 shadow-2 border-round p-fluid mb-3">
      <div class="field">
        <label for="name" :class="{'p-error': v$.product.name.$error}">Nome *</label>
        <div class="p-inputgroup">
          <InputText id="name" @blur="v$.product.name.$touch"
                     v-model="product.name" required="true"
                     maxlength="50"
                     autofocus :class="{'p-invalid': v$.product.name.$error}" />
        </div>
        <small class="p-error" v-for="error of v$.product.name.$errors"
               :key="error.$uid">{{error.$message}}</small>
      </div>
      <div class="field">
        <label for="phone">Marca</label>
        <div class="p-inputgroup">
          <InputText v-model="product.provider" maxlength="50" autofocus />
        </div>
      </div>
      <div class="field">
        <label for="phone">Quantidade</label>
        <div class="p-inputgroup">
          <InputNumber inputId="minmax-buttons" v-model="product.amount" mode="decimal" aria-valuemax="5" showButtons :min="0" :max="10000" />
        </div>
      </div>
      <div class="field">
        <label for="categoria" :class="{'p-error': v$.product.metricsType.$error}">Métrica *</label>
        <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
                        <i class="pi pi-list"></i>
                    </span>
          <Dropdown id="categoria" v-model="product.metricsType" :options="medidas"
                    @blur="v$.product.metricsType.$touch"
                    option-value="code" optionLabel="name" placeholder="Selecione uma Métrica"
                    autofocus :class="{'p-invalid': v$.product.metricsType.$error}"
          />
        </div>
        <small class="p-error" v-for="error of v$.product.metricsType.$errors"
               :key="error.$uid">{{error.$message}}</small>
      </div>
    </div>

    <Toolbar class="mb-2">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Cancelar" icon="pi pi-times" class="p-button-outlined mr-2" @click.prevent="$router.push({name: 'produtos'})"/>
          <Button label="Salvar" icon="pi pi-check" class="p-button-success" @click.prevent="save" />
        </div>
      </template>
    </Toolbar>
  </div>

</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import ProductService from "@/service/ProductService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "produto-create",
  created() {
    this.productService = new ProductService();
  },
  data() {
    return {
      product: {
        amount: 0
      },
      productId: null,
      productService: null,
      medidas: [
        {name: 'ML (MILILITRO)', code: 'ML'},
        {name: 'UND (UNIDADE)', code: 'UND'}
      ],
    }
  },
  validations() {
    return {
      product: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        metricsType: {
          required: helpers.withMessage('Campo Métrica é obrigatório.', required)
        }
      }
    }
  },
  async mounted() {
    this.productId = this.$route.query.id;
    if(this.productId) {
      const loader = this.$loading.show();
      await this.findById();
      loader.hide();
    }
  },
  methods: {
    async save() {
      const isFormValid = await this.v$.$validate();
      if(!isFormValid) {
        return;
      }
      const loading = this.$loading.show();
      try {
        this.product.active = true;
        const response = await this.productService.save(this.product);
        if (response.status === 200) {
          loading.hide();
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Produto cadastrado com sucesso.',
            life: 3000
          });
          if (!this.productId) {
            this.product = {
              amount: 0
            };
            this.v$.$reset();
          }
        }
      } catch(e) {
        loading.hide();
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    },
    async findById() {
      const response = await this.productService.findById(this.productId);
      if(response.status === 200) {
        this.product = response.data;
      }
    }
  }
}
</script>

<style scoped>

</style>
